import React, { useMemo, useCallback } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Link as MUILink } from '@mui/material';
import {
  AccordionWrapper,
  AccordionSummary,
  AccordionDetails
} from '@components/Accordion';

import config from '@configFile';
import { getCouponLinkFromCode } from '@utils/index';
import MonetizingLink from '@components/MonetizingLink';

const DealHasCoupon = ({
  promoCode,
  tag = config.AFFILIATE_TAGS.PRODUCT_PAGE,
  marginTop = '12px',
  marginBottom = '0px',
  initialOpen = false
}: {
  promoCode: string;
  tag?: string;
  marginTop?: string;
  marginBottom?: string;
  initialOpen?: boolean;
}) => {
  const [expanded, setExpanded] = React.useState<string | false>(
    initialOpen ? 'panel1' : false
  );

  const handleChange = useCallback(
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    },
    []
  );

  const couponLink = useMemo(
    () => getCouponLinkFromCode(promoCode, tag),
    [promoCode, tag]
  );

  const accordionStyles = useMemo(
    () => ({
      borderRadius: '4px',
      borderBottomLeftRadius: expanded === 'panel1' ? '0px' : '4px',
      borderBottomRightRadius: expanded === 'panel1' ? '0px' : '4px'
    }),
    [expanded]
  );

  return (
    <Box marginTop={marginTop} marginBottom={marginBottom}>
      <AccordionWrapper
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
        sx={accordionStyles}
      >
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          sx={{
            ...accordionStyles,
            border: `1px solid #392e50`
          }}
        >
          <Typography variant="body2">This deal has a coupon!</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            border: `1px solid #392e50`,
            borderTop: 'none'
          }}
        >
          <Box>
            <Typography variant="body2">
              If you don&apos;t see the coupon, it might be because you have
              already used it.{' '}
              <MonetizingLink
                href={couponLink}
                target="_blank"
                rel="nofollow"
                tag={tag}
                clickType="coupon-link-redeem-to-account-amazon"
                underline="always"
              >
                <strong>
                  Click here to check if you&apos;ve already redeemed the coupon
                  on Amazon.com.
                </strong>
              </MonetizingLink>
            </Typography>
          </Box>
          <Box marginTop="10px">
            <Typography variant="body2">
              We know that whether you&apos;ve used coupons or not can be
              frustrating so we maintain a page{' '}
              <MUILink href="/amazon-coupon-deals" color="primary">
                here
              </MUILink>{' '}
              where you can see deals grouped by their available coupon.
            </Typography>
          </Box>
        </AccordionDetails>
      </AccordionWrapper>
    </Box>
  );
};

export default DealHasCoupon;
