import React from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import {
  getPostPrice,
  parseTitle,
  copyTextToClipboard,
  getPostPrefix
} from '@utils/index';
import { useSnackbar } from '@hooks/useSnackbar';
import { DealPostType } from '@types';

interface CopyDealLinkProps extends DealPostType {
  handleClose: () => void;
}

const CopyBlogLink = (props: CopyDealLinkProps) => {
  const { handleClose, title, slug } = props;
  const { showMessage } = useSnackbar();

  const handleCopyLinkClick = (link: string) => {
    //
    copyTextToClipboard(
      `${`${getPostPrefix()}${parseTitle(title)}${getPostPrice(
        props
      )}`}\n${link}`
    );
    showMessage('Copied to clipboard!');
    handleClose();
  };

  const handleCopyBlogLink = () => {
    handleCopyLinkClick(`https://www.jungle.deals/blog/${slug}`);
  };

  return (
    <MenuItem
      key="copy-blog-link"
      onClick={() => {
        handleCopyBlogLink();
        handleClose();
      }}
      disabled={!slug}
    >
      <ListItemIcon>
        <ContentCopyIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>Copy Blog Link</ListItemText>
    </MenuItem>
  );
};

export default CopyBlogLink;
