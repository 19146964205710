import React, { useState } from 'react';
import QueryStatsIcon from '@mui/icons-material/QueryStats';

import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  DialogTitle,
  MenuItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';

import { useGetUserData } from '@hooks/useGetUserData';
import { stopPropagationForTab } from '@utils/index';
import SeeStatsContent from './components/SeeStatsContent';
import { AdminButtonProps } from './utils';

const SeeStats = ({ ASIN, onCloseCallback = () => {} }: AdminButtonProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { data: user } = useGetUserData();

  const handleModalClose = () => {
    setModalOpen(false);
    onCloseCallback();
  };

  if (!user?.isAdmin || !ASIN) {
    return null;
  }

  const renderStatModal = () => {
    if (modalOpen) {
      return (
        <Dialog
          open
          onClose={handleModalClose}
          fullWidth
          onKeyDown={stopPropagationForTab}
        >
          <DialogTitle>Stats</DialogTitle>
          <DialogContent>
            <SeeStatsContent ASIN={ASIN} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleModalClose}>Close</Button>
          </DialogActions>
        </Dialog>
      );
    }

    return null;
  };

  return (
    <>
      {renderStatModal()}
      <MenuItem
        key="admin-stat-modal"
        onClick={() => {
          setModalOpen(true);
        }}
      >
        <ListItemIcon>
          <QueryStatsIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>See Stats</ListItemText>
      </MenuItem>
    </>
  );
};

export default SeeStats;
