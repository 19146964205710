const strategyIds = [
  {
    label: 'Everyday Essentials',
    value: 'everyday-essentials-default',
    icon: 'KitchenIcon'
  },
  {
    label: `Most Loved`,
    value: 'cml',
    icon: 'WorkspacePremiumIcon'
  },
  {
    label: 'Off to College',
    value: 'off-to-college',
    icon: 'SchoolIcon'
  },
  {
    label: 'Popular Brands',
    value: 'popular-brands',
    icon: 'WhatshotIcon'
  },
  {
    label: 'Internet Famous',
    value: 'internet-famous',
    icon: 'TagIcon'
  },
  {
    label: 'Gift Guide - Adults',
    value: 'gifting-unisex-adult',
    icon: 'CardGiftcardIcon'
  },
  {
    label: 'New Parents',
    value: 'new-parents',
    icon: 'ChildFriendlyIcon'
  },
  {
    label: 'Holiday Decor',
    value: 'holiday-decor',
    icon: 'AcUnitIcon'
  },
  {
    label: `Favorite Gifts`,
    value: 'customers-favorite-gifts',
    icon: 'HeartIcon'
  },
  {
    label: 'Premium Brands',
    value: 'premium-brands',
    icon: 'WorkspacePremiumIcon'
  },
  {
    label: 'Gift Guide - Kids',
    value: 'gifting-unisex-kid',
    icon: 'ToysIcon'
  },
  {
    label: 'Back to School',
    value: 'back-to-school-none',
    icon: 'SchoolIcon'
  },
  {
    label: 'Back to School (PreK-2)',
    value: 'back-to-school-prek-2',
    icon: 'SchoolIcon'
  },
  {
    label: 'Gift Guide - Teen',
    value: 'gifting-unisex-teen',
    icon: 'CardGiftcardIcon'
  },
  {
    label: 'Winter Favorites',
    value: 'winter-favorites',
    icon: 'AcUnitIcon'
  },
  {
    label: 'Back to School (7-8)',
    value: 'back-to-school-7-8',
    icon: 'SchoolIcon'
  },
  {
    label: 'Back to School (3-6)',
    value: 'back-to-school-3-6',
    icon: 'SchoolIcon'
  },
  {
    label: 'White Elephant',
    value: 'white-elephant',
    icon: 'CardGiftcardIcon'
  },
  {
    label: 'Stocking Stuffers - Adults',
    value: 'stocking-stuffers-unisex-adult',
    icon: 'CardGiftcardIcon'
  },
  {
    label: 'Stocking Stuffers - Teen',
    value: 'stocking-stuffers-unisex-teen',
    icon: 'CardGiftcardIcon'
  },
  {
    label: 'Stocking Stuffers - Kids',
    value: 'stocking-stuffers-kids',
    icon: 'CardGiftcardIcon'
  },
  {
    label: 'Back to School (9-12)',
    value: 'back-to-school-9-12',
    icon: 'SchoolIcon'
  }
];

export default strategyIds;

/*
everyday-essentials-default	474
cml	437
off-to-college	249
popular-brands	205
internet-famous	194
everyday-essentials-private-brands	72
gifting-unisex-adult	50
customers-favorite-gifts	47
premium-brands	37
gifting-unisex-kid	36
back-to-school-none	32
back-to-school-prek-2	18
gifting-unisex-teen	16
winter-favorites	15
back-to-school-7-8	14
back-to-school-3-6	12
white-elephant	6
stocking-stuffers-unisex-adult	7
back-to-school-9-12	7
gifting-female-adult	5
gifting-female-kid	5
gifting-male-adult	5
stocking-stuffers-female-adult	4
fall-favorites	3
gifting-female-teen	3
stocking-stuffers-unisex-teen	2
  */
