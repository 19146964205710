import React, { useState, useEffect } from 'react';

import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from '@mui/material/Tooltip';
import ReportIcon from '@mui/icons-material/Report';
import IconButton from '@mui/material/IconButton';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { Typography, Box } from '@mui/material';
import { useGetDealSummaryNonEnabled } from '@hooks/useGetDealSummary';
import { useSnackbar } from '@hooks/useSnackbar';
import DealHasCoupon from '../DealHasCoupon';
import { iconButtonHoverRaiseStyle } from '../../utils/styles';
import useReportInaccurate from './useReportInaccurate';
import reportingOptions from './reportingOptions';

interface SendReportData {
  ASIN: string;
  message: string;
}

const ReportInaccurate = (props: {
  ASIN: string;
  closeDialog?: (success: boolean) => void;
  variant?: 'listItem' | 'button';
  toyMode?: boolean;
}) => {
  const { ASIN, closeDialog, variant = 'button', toyMode = false } = props;
  const { showMessage } = useSnackbar();
  const [issues, setIssues] = useState<string[]>([]);
  const [showAlertReportDialog, setShowAlertReportDialog] = useState(false);
  const { mutate: reportInaccurate } = useReportInaccurate();
  const [message, setMessage] = useState('');
  const { data: dealSummary, refetch: getDealSummary } =
    useGetDealSummaryNonEnabled(ASIN);

  useEffect(() => {
    if (showAlertReportDialog && !toyMode) {
      getDealSummary();
    }
  }, [showAlertReportDialog, toyMode]);

  const handleClose = (hadSuccess: boolean) => {
    setShowAlertReportDialog(false);
    if (closeDialog) {
      closeDialog(hadSuccess);
    }
  };

  const handleReport = ({ ASIN, message }: SendReportData) => {
    reportInaccurate(
      { ASIN, message },
      {
        onSuccess: () => {
          if (variant === 'button') {
            showMessage(
              'Thank you for your report. Our team will recheck this deal for accuracy.'
            );
          }
          handleClose(true);
        }
      }
    );
  };

  const renderButtonVariant = () => {
    return (
      <Tooltip title="Report deal information inaccurate">
        <IconButton
          key="btn-report-inaccurate"
          size="small"
          disabled={!ASIN}
          aria-label="Report inaccurate deal"
          onClick={() => {
            setShowAlertReportDialog(true);
          }}
          sx={iconButtonHoverRaiseStyle}
        >
          <ReportIcon />
        </IconButton>
      </Tooltip>
    );
  };

  const renderListItemVariant = () => {
    return (
      <MenuItem
        key="report-inaccurate"
        onClick={() => {
          setShowAlertReportDialog(true);
        }}
      >
        <ListItemIcon>
          <ReportIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Report Inaccurate</ListItemText>
      </MenuItem>
    );
  };

  const renderButtonType = () => {
    switch (variant) {
      case 'listItem':
        return renderListItemVariant();
      case 'button':
      default:
        return renderButtonVariant();
    }
  };

  return (
    <>
      {showAlertReportDialog && (
        <div>
          <Dialog
            open
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Report a problem</DialogTitle>
            <DialogContent>
              {dealSummary?.promoCode ? (
                <Box data-testid="dialog-promo-code">
                  <DealHasCoupon
                    marginBottom="12px"
                    promoCode={dealSummary?.promoCode}
                    initialOpen
                  />
                </Box>
              ) : null}
              <Box marginTop="8px">
                <Typography gutterBottom>
                  If you think there is an issue with this deal, please report
                  it via the button below. We will check the deal and will take
                  appropriate action.
                </Typography>
              </Box>
              <Box marginTop="16px">
                <FormControl component="fieldset" variant="standard">
                  <FormLabel
                    component="legend"
                    sx={{
                      marginBottom: '8px'
                    }}
                  >
                    Select all issues so we know where to look (optional)
                  </FormLabel>
                  <FormGroup>
                    {reportingOptions.map((issue) => (
                      <FormControlLabel
                        key={issue.value}
                        control={
                          <Checkbox
                            checked={issues.includes(issue.value)}
                            onChange={() => {
                              if (issues.includes(issue.value)) {
                                setIssues(
                                  issues.filter((i) => i !== issue.value)
                                );
                              } else {
                                setIssues([...issues, issue.value]);
                              }
                            }}
                            name={issue.value}
                          />
                        }
                        label={issue.label}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              </Box>
              <Box marginTop="8px">
                <TextField
                  margin="dense"
                  label="Additional details (optional)"
                  placeholder='e.g. "The price is not correct"'
                  fullWidth
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  handleClose(false);
                }}
                variant="outlined"
              >
                Close
              </Button>
              <Button
                onClick={() =>
                  handleReport({
                    ASIN,
                    message: `${message} Issues: ${
                      issues.length === 1 ? issues[0] : issues.join(', ')
                    }`
                  })
                }
                variant="contained"
                autoFocus
              >
                Report
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
      {renderButtonType()}
    </>
  );
};

export default ReportInaccurate;
