import React, { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Dialog,
  Checkbox,
  DialogActions,
  DialogContent,
  Button,
  DialogTitle,
  MenuItem,
  FormControlLabel,
  FormGroup,
  ListItemIcon,
  ListItemText,
  DialogContentText
} from '@mui/material';
import { useGetUserData } from '@hooks/useGetUserData';
import { useUpdateDeal } from '@hooks/useUpdateDeal';
import { useSnackbar } from '@hooks/useSnackbar';
import { stopPropagationForTab } from '@utils/index';
import SeeStatsContent from './components/SeeStatsContent';
import { AdminButtonProps } from './utils';

const useDeleteDealState = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [foreverDeleteDeal, setForeverDeleteDeal] = useState(false);
  return {
    modalOpen,
    setModalOpen,
    foreverDeleteDeal,
    setForeverDeleteDeal
  };
};

const DeleteDealDialog = ({
  ASIN,
  foreverDeleteDeal,
  isUpdating,
  handleModalClose,
  setForeverDeleteDeal,
  updateDeal,
  showMessage
}: {
  ASIN: string;
  foreverDeleteDeal: boolean;
  isUpdating: boolean;
  handleModalClose: () => void;
  setForeverDeleteDeal: React.Dispatch<React.SetStateAction<boolean>>;
  updateDeal: ReturnType<typeof useUpdateDeal>['mutate'];
  showMessage: (message: string) => void;
}) => {
  const handleDelete = () => {
    const params: Record<string, boolean> = foreverDeleteDeal
      ? { forever_delete_deal: true }
      : { delete_deal: true };

    updateDeal(
      {
        update: { ...params },
        ASIN
      },
      {
        // eslint-disable-next-line no-alert
        onError: () => alert('An error happened, check the logs'),
        onSuccess: () => {
          showMessage('Deal marked for deletion! Updating...');
          handleModalClose();
        }
      }
    );
  };

  return (
    <Dialog
      open
      onClose={handleModalClose}
      fullWidth
      onKeyDown={stopPropagationForTab}
    >
      <DialogTitle>Delete Item?</DialogTitle>
      <DialogContent>
        <DialogContentText>{ASIN}</DialogContentText>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={foreverDeleteDeal}
                onChange={() => setForeverDeleteDeal(!foreverDeleteDeal)}
              />
            }
            label="Delete forever"
          />
        </FormGroup>
        <SeeStatsContent ASIN={ASIN} />
      </DialogContent>
      <DialogActions>
        <Button disabled={isUpdating} onClick={handleModalClose}>
          Cancel
        </Button>
        <Button
          disabled={isUpdating}
          onClick={handleDelete}
          variant="contained"
          color="primary"
        >
          {isUpdating ? 'Deleting...' : 'Delete'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DeleteDeal = ({ ASIN, onCloseCallback = () => {} }: AdminButtonProps) => {
  const { showMessage } = useSnackbar();
  const { data: user } = useGetUserData();
  const { mutate: updateDeal, isLoading: isUpdating } = useUpdateDeal();
  const { modalOpen, setModalOpen, foreverDeleteDeal, setForeverDeleteDeal } =
    useDeleteDealState();

  const handleModalClose = () => {
    setModalOpen(false);
    onCloseCallback();
  };

  if (!user?.isAdmin || !ASIN) {
    return null;
  }

  return (
    <>
      {modalOpen && (
        <DeleteDealDialog
          ASIN={ASIN}
          foreverDeleteDeal={foreverDeleteDeal}
          isUpdating={isUpdating}
          handleModalClose={handleModalClose}
          setForeverDeleteDeal={setForeverDeleteDeal}
          updateDeal={updateDeal}
          showMessage={showMessage}
        />
      )}
      <MenuItem key="admin-delete-deal" onClick={() => setModalOpen(true)}>
        <ListItemIcon>
          <DeleteIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Delete</ListItemText>
      </MenuItem>
    </>
  );
};

export default DeleteDeal;
