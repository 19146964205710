import React from 'react';

import { Select, FormControl, InputLabel, MenuItem } from '@mui/material';
import { allCategories } from '@utils/categories';

const SelectCategory = ({
  category,
  setCategory
}: {
  category: string;
  setCategory: (category: string) => void;
}) => {
  return (
    <FormControl margin="normal" fullWidth>
      <InputLabel
        id="id-category"
        sx={{
          backgroundColor: 'white'
        }}
      >
        Category
      </InputLabel>
      <Select
        labelId="id-category"
        value={category}
        onChange={(e) => {
          setCategory(e.target.value as string);
        }}
        displayEmpty
      >
        <MenuItem value="true">Yes</MenuItem>
        {allCategories.map((category) => (
          <MenuItem key={category} value={category}>
            {category}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectCategory;
