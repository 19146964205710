import React, { useState, useEffect } from 'react';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  DialogTitle,
  MenuItem,
  Typography,
  ListItemIcon,
  ListItemText,
  Divider,
  TextField,
  Box
} from '@mui/material';
import { useGetUserData } from '@hooks/useGetUserData';
import { useGetDealSummaryAdmin } from '@hooks/useGetDealSummary';
import { useUploadNewCompareImage } from '@hooks/useDeals';
import useLocalStorage from '@hooks/useLocalStorage';
import { useUpdateDeal } from '@hooks/useUpdateDeal';
import { stopPropagationForTab } from '@utils/index';
import { AdminButtonProps } from './utils';

const CompareDetailsContent = ({ ASIN }: { ASIN: string }) => {
  const { data: dealSummaryAdmin, refetch } = useGetDealSummaryAdmin(ASIN);
  const [file, setFile] = useState<File | null>(null);
  const [price, setPrice] = useState<string>('');
  const [store, setStore] = useLocalStorage('store', 'Safeway');
  const uploadImage = useUploadNewCompareImage();
  const { mutate: updateDeal, isLoading: isUpdating } = useUpdateDeal();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
    }
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (file) {
      uploadImage.mutate(
        { pictureFile: file, ASIN, price, store },
        {
          onSuccess: () => {
            console.log('success');
            refetch();
            setFile(null);
            setPrice('');
          }
        }
      );
    }
  };

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <>
      {dealSummaryAdmin?.compareImage ? (
        <>
          <img
            src={dealSummaryAdmin.compareImage}
            alt=""
            style={{
              maxWidth: '100%'
            }}
          />
          <Button
            disabled={isUpdating}
            onClick={() => {
              updateDeal({
                update: {
                  compare_to: null,
                  compare_image: null,
                  compare_price: null,
                  compare_image_width: null,
                  compare_image_height: null
                },
                ASIN
              });
            }}
          >
            Remove compare details
          </Button>
        </>
      ) : (
        <Typography variant="body1" gutterBottom>
          No compare image
        </Typography>
      )}
      {dealSummaryAdmin && dealSummaryAdmin.compareImage === null ? (
        <>
          <form onSubmit={handleSubmit}>
            <Box marginBottom="8px">
              <TextField
                type="file"
                onChange={handleFileChange}
                variant="outlined"
              />
            </Box>
            <Box marginBottom="8px">
              <TextField
                label="Price"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </Box>
            <Box marginBottom="8px">
              <TextField
                label="Store"
                value={store}
                onChange={(e) => setStore(e.target.value)}
              />
            </Box>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={uploadImage.isLoading || store === '' || price === ''}
            >
              Add compare details
            </Button>
            {uploadImage.isError && <p>Error uploading image</p>}
          </form>
          <Divider
            style={{
              marginBottom: 12,
              marginTop: 8
            }}
          />
        </>
      ) : null}
      {!dealSummaryAdmin ? (
        <Typography variant="body1">Loading...</Typography>
      ) : null}
    </>
  );
};

const CompareDetails = ({
  ASIN,
  onCloseCallback = () => {}
}: AdminButtonProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { data: user } = useGetUserData();

  const handleModalClose = () => {
    setModalOpen(false);
    onCloseCallback();
  };

  if (!user?.isAdmin || !ASIN) {
    return null;
  }

  const renderCompareModal = () => {
    if (modalOpen) {
      return (
        <Dialog
          open
          onClose={handleModalClose}
          fullWidth
          onKeyDown={stopPropagationForTab}
        >
          <DialogTitle>Compare Details</DialogTitle>
          <DialogContent>
            <CompareDetailsContent ASIN={ASIN} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleModalClose}>Close</Button>
          </DialogActions>
        </Dialog>
      );
    }

    return null;
  };

  return (
    <>
      {modalOpen ? renderCompareModal() : null}
      <MenuItem
        key="admin-compare-details-modal"
        onClick={() => {
          setModalOpen(true);
        }}
      >
        <ListItemIcon>
          <QueryStatsIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Compare Details</ListItemText>
      </MenuItem>
    </>
  );
};

export default CompareDetails;
