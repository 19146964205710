import React, { useState } from 'react';
import TitleIcon from '@mui/icons-material/Title';
import {
  Dialog,
  DialogTitle,
  MenuItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import { useGetUserData } from '@hooks/useGetUserData';
import { stopPropagationForTab } from '@utils/index';
import ChangeInfoLogic from './components/ChangeInfoLogic';
import { AdminButtonProps } from './utils';

const ChangeInfo = ({ ASIN, onCloseCallback = () => {} }: AdminButtonProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { data: user } = useGetUserData();

  const handleModalClose = () => {
    setModalOpen(false);
    onCloseCallback();
  };

  if (!user?.isAdmin || !ASIN) {
    return null;
  }

  const renderChangeInfoModal = () => {
    if (modalOpen) {
      return (
        <Dialog
          open
          onClose={handleModalClose}
          fullWidth
          onKeyDown={stopPropagationForTab}
          maxWidth="xl"
        >
          <DialogTitle>Change Info</DialogTitle>
          <ChangeInfoLogic ASIN={ASIN} onCloseCallback={handleModalClose} />
        </Dialog>
      );
    }

    return null;
  };

  return (
    <>
      {renderChangeInfoModal()}
      <MenuItem
        key="admin-change-info"
        onClick={() => {
          setModalOpen(true);
        }}
      >
        <ListItemIcon>
          <TitleIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Change Info</ListItemText>
      </MenuItem>
    </>
  );
};

export default ChangeInfo;
