export default [
  {
    label: 'Price is incorrect',
    value: 'price'
  },
  {
    label: 'Deal is out of stock',
    value: 'outOfStock'
  },
  {
    label: 'Product is not as described',
    value: 'productNotAsDescribed'
  },
  {
    label: "I can't see the coupon",
    value: 'cantSeeCoupon'
  },
  {
    label: 'Not eligible for Subscribe & Save discount',
    value: 'notEligibleForSubscribeAndSave'
  },
  {
    label: 'Inaccurate Subscribe & Save discount',
    value: 'inaccurateSubscribeAndSaveDiscount'
  },
  {
    label: 'No promotional deal available',
    value: 'noPromotionalDeal'
  },
  {
    label:
      "There's an additional discount available (please include details below)",
    value: 'additionalDiscount'
  }
];
